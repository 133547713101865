import { ActionSchemaType } from "@/components/Form/FlowForm/formValidation/action";
import { FieldSchemaType } from "@/components/Form/FlowForm/formValidation/fields";
import { StepSchemaType } from "@/components/Form/FlowForm/formValidation/flow";
import { PermissionSchemaType } from "@/components/Form/FlowForm/formValidation/permission";
import { ResultSchemaType } from "@/components/Form/FlowForm/formValidation/result";
import { ResultType } from "@/graphql/generated/graphql";

interface GenerateStepConfigProps {
  stepId: string;
  permission: PermissionSchemaType;
  responseFields: FieldSchemaType[];
  result: ResultSchemaType[];
  action: ActionSchemaType | undefined;
}

export const generateStepConfig = ({
  stepId,
  permission,
  responseFields,
  result,
  action,
}: GenerateStepConfigProps): StepSchemaType => {
  const allowMultipleResponses = [ResultType.LlmSummary, ResultType.RawAnswers].includes(result[0]?.type);
  return {
    stepId,
    fieldSet: {
      fields: responseFields,
      locked: false,
    },
    response:
      responseFields.length === 0
        ? undefined
        : {
            permission,
            canBeManuallyEnded: true,
            expirationSeconds: 259200,
            allowMultipleResponses,
            minResponses: 1,
          },
    result,
    action,
  };
};
