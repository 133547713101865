import { OptionSelectionCountLimit } from "../formValidation/fields";

export const maxOptionSelectionsOptions = [
  { name: "1 selection", value: 1 },
  { name: "2 selection", value: 2 },
  { name: "3 selections", value: 3 },
  { name: "4 selections", value: 4 },
  { name: "5 selections", value: 5 },
  { name: "No selection limit", value: OptionSelectionCountLimit.None },
];
